/*
Polyfills to allow us to write reasonably modern (ES6) code.

Most of these are required simply for IE11, which is supported until 2025, and
currently sits at around 1.8% of our userbase, with no sign of decreasing :-(

When we finally drop support for IE11, we can more or less get rid of all of
this file. In the meantime, use the following resources to check if we
need to enable some features:
 - ES6 compat table: http://kangax.github.io/compat-table/es6/
 - CanIUse: http://caniuse.com
*/

// IntersectionObserver API
require('intersection-observer');

// Core-js - standard ES6 features (no ES7 yet)
require('core-js/es');

// Async/Await
require('regenerator-runtime');

// Core-js - ok a little bit of ES7
// require('core-js/es/array/includes');

// Fetch - the new fancy for doing xmlhttprequests
require('whatwg-fetch');

// ClassList - make sure it works as expected everywhere (including SVG elements)
require('classlist-polyfill');

// Element.prototype.closest
require('element-closest');
